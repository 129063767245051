@import url("https://use.typekit.net/vfo4oyg.css");

@font-face {
    font-family: "Noto-Regular";
    src: local("Noto_Sans"),
    url("./assets/fonts/Noto_Sans/NotoSans-Regular.ttf") format("truetype");
}


@font-face {
    font-family: co-text;
    src: local("co-text"),
    url("https://use.typekit.net/vfo4oyg.css") format("truetype");
}

